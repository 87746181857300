import { Modal, message } from 'antd';
import { DataTableAction } from '../../../components/dumb/dataTable/DataTable';
import { ProjectOutDto } from '../../../services/api/apiClient';
import { routes } from '../../../routing/routes';
import { ApiClient } from '../../../services/api/api';

export const useGetProjectsListActions = (refreshCallback: () => void): DataTableAction<ProjectOutDto>[] => {
  return [
    {
      component: () => <span>Edit</span>,
      method: (entity: ProjectOutDto) => {
        document.location.href = `${routes.editProject.route}?${new URLSearchParams(entity)}`;
      },
    },
    {
      component: () => <span>Delete</span>,
      method: (entity: ProjectOutDto) => {
        Modal.confirm({
          okButtonProps: { danger: true },
          title: `Are you sure you want to delete project: ${entity.name}`,
          okText: 'Delete',
          onOk: () => {
            message.info('Deleting...');
            ApiClient.projectsController_delete(entity.id)
              .then(() => {
                message.success('Succesfully deleted');
                refreshCallback();
              })
              .catch(() => message.error('Could not delete project, please try again later'));
          },
        });
      },
    },
  ];
};
