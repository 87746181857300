import { Form, Input, Button } from 'antd';
import { routes } from '../../../routing/routes';

type Props = {
  onSubmit: (data: ProjectFormValues) => void;
  isLoading: boolean;
  initialValue?: ProjectFormValues;
};

const ProjectNameInput = () => (
  <Form.Item label="Project Name" name="name" rules={[{ required: true, message: 'Please fill project name' }]}>
    <Input placeholder="Start typing..." />
  </Form.Item>
);

const ProjectIdInput = () => (
  <Form.Item label="Id" name="id">
    <Input disabled />
  </Form.Item>
);

const SubmitButton = ({ isLoading, isEdit }: { isLoading: boolean; isEdit: boolean }) => (
  <Form.Item>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        onClick={() => {
          document.location.href = routes.projectsList.route;
        }}
      >
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" loading={isLoading}>
        {isEdit ? 'Create' : 'Update'}
      </Button>
    </div>
  </Form.Item>
);

export const ProjectForm = ({ onSubmit, isLoading, initialValue }: Props) => {
  return (
    <Form layout="vertical" onFinish={onSubmit} initialValues={initialValue}>
      {initialValue && <ProjectIdInput />}

      <ProjectNameInput />

      <SubmitButton isLoading={isLoading} isEdit={!initialValue} />
    </Form>
  );
};

export type ProjectFormValues = {
  id?: number;
  name: string;
};
