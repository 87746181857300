import { Card, message } from 'antd';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ApiClient } from '../../services/api/api';
import { ProjectInDto } from '../../services/api/apiClient';
import { routes } from '../../routing/routes';
import { ProjectForm, ProjectFormValues } from '../../components/smart/ProjectForm/ProjectForm';

export const CreateProjectPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback((data: ProjectFormValues) => {
    createProject(data, setIsLoading);
  }, []);

  return (
    <Card style={{ maxWidth: '620px' }}>
      <ProjectForm onSubmit={onSubmit} isLoading={isLoading} />
    </Card>
  );
};

const createProject = (data: ProjectFormValues, setIsLoading: Dispatch<SetStateAction<boolean>>): void => {
  setIsLoading(true);
  ApiClient.projectsController_create(new ProjectInDto({ ...data }))
    .then(() => message.success('Project created succesfully'))
    .then(() => {
      document.location.href = routes.projectsList.route;
    })
    .catch(() => message.error('Could not create project, please try again later'))
    .finally(() => setIsLoading(false));
};
