import { DataTableColumns } from '../../../components/dumb/dataTable/DataTable';
import { ProjectOutDto } from '../../../services/api/apiClient';

export const useGetProjectsListColumns = (): DataTableColumns<ProjectOutDto>[] => {
  return [
    {
      title: 'Id',
      render: (entity: ProjectOutDto) => entity.id,
      width: '20%',
    },
    {
      title: 'Name',
      render: (entity: ProjectOutDto) => entity.name,
      width: '80%',
    },
  ];
};
