import { SearchOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Input, Pagination } from 'antd';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { debounce } from '../../../utilities/debounce';
import { scrollToElement } from '../../../utilities/scrollToElement';

type Props<TFilter> = {
  onChange: (newFilter: TFilter) => void;
  onSearch: (searchValue: string) => void;
  filter: any;
  children: ReactNode;
  total: number;
  showSearch?: boolean;
  parentId?: string;
};

const StyledInput = styled(Input)`
  border-radius: 6px;
  padding-left: 30px;

  max-width: 100%;

  @media only screen and (max-width: 480px) {
    width: 100%;
    max-width: 100vw;
  }
`;

const TableFilter = <TFilter extends { skip: number; take: number; searchValue?: string | undefined }>({
  onChange,
  onSearch,
  parentId,
  filter,
  children,
  total,
  showSearch = true,
}: Props<TFilter>): JSX.Element => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((nextValue: any) => onSearch(nextValue), 300),
    [],
  );

  const [inputValue, setInputValue] = useState<string>(filter.searchValue);

  useEffect(() => {
    debouncedSearch(inputValue);
  }, [inputValue, debouncedSearch]);

  const onPageChange = async (page: number) => {
    scrollToElement({ id: parentId ?? 'filter-bar', duration: 250, offset: -230 });

    setTimeout(() => {
      onChange({ ...filter, skip: (page - 1) * filter.take });
    }, 300);
  };

  useEffect(() => {
    setInputValue(filter.searchValue);
  }, [filter.searchValue]);

  return (
    <div>
      {showSearch && (
        <StyledInput
          id="filter-bar"
          prefix={<SearchOutlined style={{ fontSize: '20px' }} />}
          placeholder="Search"
          size="large"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          allowClear={true}
        />
      )}
      {children}

      <Pagination
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        current={filter.skip / filter.take + 1}
        total={total}
        showSizeChanger={false}
        pageSize={filter.take}
        onChange={onPageChange}
      />
    </div>
  );
};

export default TableFilter;
