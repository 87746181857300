import { Statistic, Typography } from 'antd';
import { TasksSummaryOutDto } from '../../../services/api/apiClient';
import { formatNumberWithLocale } from '../../../utilities/intl';
const { Title } = Typography;

type Props = {
  data?: TasksSummaryOutDto;
};

export const TaskListSummary = ({ data }: Props) => {
  return (
    <div>
      <Title level={4}>Totals:</Title>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Statistic title="Hours" value={formatNumberWithLocale(data?.totalHours ?? 0)} />
        <Statistic title="Tasks" value={formatNumberWithLocale(data?.totalTasks ?? 0)} />
        <Statistic title="Projects" value={formatNumberWithLocale(data?.totalProjects ?? 0)} />
        <Statistic title="Users" value={formatNumberWithLocale(data?.totalUsers ?? 0)} />
      </div>
    </div>
  );
};
