import { Card, message } from 'antd';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ApiClient } from '../../services/api/api';
import { TaskInDto } from '../../services/api/apiClient';
import { routes } from '../../routing/routes';
import { TaskForm, TaskFormValues } from '../../components/smart/TaskForm/TaskForm';

export const CreateTaskPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback((data: TaskFormValues) => {
    createTask(data, setIsLoading);
  }, []);

  return (
    <Card style={{ maxWidth: '620px' }}>
      <TaskForm onSubmit={onSubmit} isLoading={isLoading} />
    </Card>
  );
};

const createTask = (data: TaskFormValues, setIsLoading: Dispatch<SetStateAction<boolean>>): void => {
  setIsLoading(true);
  ApiClient.tasksController_create(
    new TaskInDto({ ...data, projectName: '', weekStart: data.week.startOf('week').toDate(), weekEnd: data.week.endOf('week').add(-59, 'seconds').toDate() }),
  )
    .then(() => {
      message.success('Task created succesfully');
      document.location.href = routes.tasksList.route;
    })
    .catch(() => message.error('Could not create tasks, please try again later'))
    .finally(() => setIsLoading(false));
};
