import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { message } from 'antd';

export const useApiCall = <TResponse>(key: any[], queryFn: any) => {
  const { data, isLoading, refetch, isRefetching, error } = useQuery<TResponse>({ queryKey: key, queryFn: queryFn });

  useEffect(() => {
    if (error) {
      message.error(`Could not fetch data, api error: ${error.message}`);
    }
  }, [error]);

  return {
    data,
    isLoading: isLoading || isRefetching,
    refetch,
  };
};
