import { ApiKeys } from '../../services/api/apiKeys';
import { ApiClient } from '../../services/api/api';
import TableFilter from '../../components/dumb/tableFilter/TableFilter';
import { DataTable } from '../../components/dumb/dataTable/DataTable';
import { useCallback, useState } from 'react';
import { UserFilterModel, UsersFilterResponse } from '../../services/api/apiClient';
import { useGetUsersListColumns } from './utilities/useGetUsersListColumns';
import { Button } from 'antd';
import { useApiCall } from '../../utilities/useApiCall';
import { ReloadOutlined } from '@ant-design/icons';

const defaultFilter = new UserFilterModel({ skip: 0, take: 20 });

export const UsersListPage = () => {
  const [filter, setFilter] = useState<UserFilterModel>(defaultFilter);
  const { data, isLoading, refetch } = useApiCall<UsersFilterResponse>([ApiKeys.getUsers, filter], () => ApiClient.usersController_filter(filter));

  const onRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const columns = useGetUsersListColumns();

  return (
    <>
      <TableFilter
        filter={filter}
        showSearch
        total={data?.total ?? 0}
        onChange={(v) => setFilter(new UserFilterModel({ ...v }))}
        onSearch={(s) => setFilter((old) => new UserFilterModel({ ...old, searchValue: s }))}
      >
        <div id="actions" style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          <Button icon={<ReloadOutlined />} loading={isLoading} type="dashed" onClick={() => onRefresh()}>
            Refresh
          </Button>
        </div>

        <DataTable data={data?.users ?? []} columns={columns} loading={isLoading} />
      </TableFilter>
    </>
  );
};
