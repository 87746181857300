import { TaskInDto } from '../../../services/api/apiClient';
import { DataTable, DataTableColumns } from '../../dumb/dataTable/DataTable';
import { formatDate } from '../../../utilities/formatDate';
import Title from 'antd/es/typography/Title';
import { isValidDate } from '../../../utilities/isValidDate';
import { formatNumberWithLocale } from '../../../utilities/intl';
import dayjs from 'dayjs';

type Props = {
  tasks: TaskInDto[];
};

export const ValidatedImportTasksTable = ({ tasks }: Props) => {
  const columns = useGetColumns();

  return tasks?.length > 0 ? (
    <div>
      <Title level={4}>Tasks to import. Please make sure given project name is matching names in db.</Title>
      <DataTable data={tasks} columns={columns} />
    </div>
  ) : (
    <></>
  );
};

const useGetColumns = (): DataTableColumns<TaskInDto>[] => [
  {
    title: 'Project name',
    render: (entity: TaskInDto) => (entity.projectName?.length > 0 ? entity.projectName : <InvalidCell />),
  },
  {
    title: 'Week start',
    render: (entity: TaskInDto) => (isValidDate(entity.weekStart) ? formatDate(dayjs(entity.weekStart).utc().toDate()) : <InvalidCell />),
  },
  {
    title: 'Week end',
    render: (entity: TaskInDto) => (isValidDate(entity.weekEnd) ? formatDate(dayjs(entity.weekEnd).utc().toDate()) : <InvalidCell />),
  },
  {
    title: 'Hours',
    render: (entity: TaskInDto) => (Number(entity.hours) ? formatNumberWithLocale(entity.hours) : <InvalidCell />),
  },
  {
    title: 'Task',
    render: (entity: TaskInDto) => (entity.task?.length > 0 ? entity.task : <InvalidCell />),
  },
  {
    title: 'Reference',
    render: (entity: TaskInDto) => entity.reference,
  },
];

const InvalidCell = () => {
  return <span style={{ color: 'red' }}>INVALID</span>;
};
