/* eslint-disable @typescript-eslint/ban-types */
import { Dropdown, MenuProps } from 'antd';
import { ReactNode } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { StyledDataTable } from './DataTable.styled';
import { Guid } from '../../../utilities/guid';

export interface DataTableProps<TData> {
  columns: DataTableColumns<TData>[];
  actions?: DataTableAction<TData>[];
  data: TData[];
  loading?: boolean;
  className?: string;
}

export interface DataTableAction<TData> {
  component: (entity: TData) => ReactNode;
  method: (entity: TData) => void;
  shouldRender?: (entity: TData) => boolean;
}

export interface DataTableColumns<TData> {
  title: string;
  render: (entity: TData) => ReactNode;
  width?: string;
}

export const DataTable = <TData extends object>({ columns, actions, data, loading, className }: DataTableProps<TData>): JSX.Element => {
  return (
    <>
      <StyledDataTable
        className={className}
        bordered
        columns={[
          ...columns,
          ...(actions
            ? [
                {
                  render: (_: any, record: TData) => <DataTableActions<TData> actions={actions} record={record} />,
                  width: '5%',
                },
              ]
            : []),
        ]}
        rowKey={() => Guid.newGuid()}
        dataSource={data}
        pagination={false}
        loading={loading}
      />
    </>
  );
};

interface DataTableActionsProps<TData> {
  actions: DataTableAction<TData>[];
  record: TData;
}

export const DataTableActions = <TData extends object>({ actions, record }: DataTableActionsProps<TData>) => {
  const mappedActions = actions?.filter((action) => (action.shouldRender && action.shouldRender(record)) || action.shouldRender === undefined);

  const newMenu = (givenRecord: TData): MenuProps['items'] =>
    actions
      ?.filter((action) => (action.shouldRender && action.shouldRender(givenRecord)) || action.shouldRender === undefined)
      ?.map((action, id) => ({
        key: id,
        label: (
          <div onClick={() => action.method(givenRecord)} key={id}>
            <span>{action.component(givenRecord)}</span>
          </div>
        ),
      }));

  return (
    <div>
      {mappedActions?.length > 0 && (
        <Dropdown menu={{ items: newMenu(record) }} getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}>
          <MoreOutlined style={{ fontSize: '1rem' }} />
        </Dropdown>
      )}
    </div>
  );
};
