import { useContext } from 'react';
import { AuthContext } from '../contexts/auth/AuthContext';

export enum RoleType {
  Admin = 'admin',
  User = 'user',
}

export const useIsAdmin = () => {
  const { auth } = useContext(AuthContext);

  return auth?.user?.roleType === RoleType.Admin;
};
