import { Form, Input, InputNumber, Button } from 'antd';
import { routes } from '../../../routing/routes';
import { ProjectsSelect } from '../ProjectsSelect/ProjectsSelect';
import { Dayjs } from 'dayjs';
import MyDatePicker from '../../dumb/myDatePicker/MyDatePicker';

type Props = {
  onSubmit: (data: TaskFormValues) => void;
  isLoading: boolean;
  initialValue?: TaskFormValues;
};

const TaskNameInput = () => (
  <Form.Item label="Task Name" name="task" rules={[{ required: true, message: 'Please fill task name' }]}>
    <Input placeholder="Start typing..." />
  </Form.Item>
);

const TaskReferenceInput = () => (
  <Form.Item label="Task Reference" name="reference" rules={[{ required: true, message: 'Please fill task reference' }]}>
    <Input placeholder="Start typing..." />
  </Form.Item>
);

const TaskIdInput = () => (
  <Form.Item label="Id" name="id">
    <Input disabled />
  </Form.Item>
);

const WeekPicker = () => (
  <Form.Item label="Week" name="week" rules={[{ required: true, message: 'Please select the week' }]}>
    <MyDatePicker picker="week" />
  </Form.Item>
);

const HoursInput = () => (
  <Form.Item label="Hours" name="hours" rules={[{ required: true, message: 'Please fill hours' }]}>
    <InputNumber style={{ minWidth: '150px' }} min={0.5} step={0.5} />
  </Form.Item>
);

const ProjectsSelectFormControl = () => (
  <Form.Item label="Project" name="projectId" rules={[{ required: true, message: 'Please select the project' }]}>
    <ProjectsSelect />
  </Form.Item>
);

const SubmitButton = ({ isLoading, isEdit }: { isLoading: boolean; isEdit: boolean }) => (
  <Form.Item>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        htmlType="submit"
        onClick={() => {
          document.location.href = routes.tasksList.route;
        }}
      >
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" loading={isLoading}>
        {isEdit ? 'Create' : 'Update'}
      </Button>
    </div>
  </Form.Item>
);

export const TaskForm = ({ onSubmit, isLoading, initialValue }: Props) => {
  return (
    <Form layout="vertical" onFinish={onSubmit} initialValues={initialValue}>
      {initialValue && <TaskIdInput />}

      <TaskNameInput />

      <TaskReferenceInput />

      <ProjectsSelectFormControl />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <WeekPicker />

        <HoursInput />
      </div>

      <SubmitButton isLoading={isLoading} isEdit={!initialValue} />
    </Form>
  );
};

export type TaskFormValues = {
  id?: number;
  week: Dayjs;
  task: string;
  hours: number;
  projectId: number;
  reference: string;
};
