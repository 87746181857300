import { DataTableColumns } from '../../../components/dumb/dataTable/DataTable';
import { UserOutDto } from '../../../services/api/apiClient';

export const useGetUsersListColumns = (): DataTableColumns<UserOutDto>[] => {
  return [
    {
      title: 'Id',
      render: (entity: UserOutDto) => entity.id,
      width: '10%',
    },
    {
      title: 'First name',
      render: (entity: UserOutDto) => entity.firstName,
      width: '30%',
    },
    {
      title: 'Last name',
      render: (entity: UserOutDto) => entity.lastName,
      width: '30%',
    },
    {
      title: 'Email',
      render: (entity: UserOutDto) => entity.email,
      width: '20%',
    },
    {
      title: 'Role type',
      render: (entity: UserOutDto) => entity.roleType,
      width: '10%',
    },
  ];
};
