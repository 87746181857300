// context/todoContext.tsx
import React, { createContext, useEffect, useState } from 'react';
import { AuthenticationOutDto } from '../../services/api/apiClient';
import { ApiClient } from '../../services/api/api';
import { message } from 'antd';

interface IAuthContext {
  auth: AuthenticationOutDto | null; // Replace with your actual authentication state type
  setAuth: React.Dispatch<React.SetStateAction<AuthenticationOutDto | null>>; // Replace with the function to set authentication state
}

export const AuthContext = createContext<IAuthContext>({ auth: null, setAuth: () => {} });

type Props = {
  children: React.ReactNode;
};

const authKey = 'Authentication';

export const AuthContextProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState<AuthenticationOutDto | null>(null);

  useEffect(() => {
    const localStorageAuth = localStorage.getItem(authKey);

    if (localStorageAuth) {
      const auth = JSON.parse(localStorage.getItem(authKey) as string) as AuthenticationOutDto;
      getCurrentUser(setAuth, auth);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <div>{children}</div>
    </AuthContext.Provider>
  );
};

function getCurrentUser(setAuth: React.Dispatch<React.SetStateAction<AuthenticationOutDto | null>>, auth: AuthenticationOutDto) {
  ApiClient.authenticationController_me()
    .then((response) => {
      setAuth(new AuthenticationOutDto({ ...auth, user: response }));
    })
    .catch(() => message.error('Could not fetch user'));
}
