import { Select } from 'antd';
import { useApiCall } from '../../../utilities/useApiCall';
import { ApiKeys } from '../../../services/api/apiKeys';
import { ApiClient } from '../../../services/api/api';
import { ProjectFilterModel, ProjectsFilterResponse } from '../../../services/api/apiClient';

type Props = {
  onChange?: (value: number) => void;
  value?: number;
  style?: React.CSSProperties;
};

export const ProjectsSelect = (props: Props) => {
  const { data, isLoading } = useApiCall<ProjectsFilterResponse>([ApiKeys.getProjects, 'select'], () => ApiClient.projectsController_getFiltered(new ProjectFilterModel({ skip: 0, take: 100 })));

  const filterOption = (input: string, option?: { label: string; value: number }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return <Select style={{ width: '100%' }} placeholder="Search projects..." filterOption={filterOption} showSearch allowClear loading={isLoading} options={data?.projects?.map((project) => ({ label: project.name, value: project.id }))} {...props}></Select>;
};
