import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';

export const routes = {
  home: {
    route: '/',
    title: 'Home Page',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Home',
      },
    ] as Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  },
  tasksList: {
    route: '/tasks-list',
    title: 'Tasks List',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Tasks',
      },
      {
        title: 'List',
      },
    ] as Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  },
  createTask: {
    route: '/tasks/create',
    title: 'Create Task',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Tasks',
      },
      {
        title: 'Create',
      },
    ],
  },
  importTasks: {
    route: '/tasks/import',
    title: 'Import Tasks',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Tasks',
      },
      {
        title: 'Import',
      },
    ],
  },
  editTask: {
    route: '/tasks/edit',
    title: 'Edit Task',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Tasks',
      },
      {
        title: 'Edit',
      },
    ],
  },
  projectsList: {
    route: '/projects-list',
    title: 'Projects List',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Projects',
      },
      {
        title: 'List',
      },
    ] as Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  },
  createProject: {
    route: '/projects/create',
    title: 'Create Project',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Projects',
      },
      {
        title: '    Create',
      },
    ] as Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  },
  editProject: {
    route: '/projects/edit',
    title: 'Edit Project',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Projects',
      },
      {
        title: 'Edit',
      },
    ],
  },
  usersList: {
    route: '/users-list',
    title: 'Users List',
    breadcrumbs: [
      {
        title: 'Timesheets v2',
      },
      {
        title: 'Users',
      },
      {
        title: 'List',
      },
    ] as Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  },
  login: {
    route: '/login',
    title: 'Login',
    breadcrumbs: [] as Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  },
};
