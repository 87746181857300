import { Card, message } from 'antd';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ApiClient } from '../../services/api/api';
import { ProjectInDto, ProjectOutDto } from '../../services/api/apiClient';
import { routes } from '../../routing/routes';
import { ProjectForm, ProjectFormValues } from '../../components/smart/ProjectForm/ProjectForm';
import { useSearchParams } from 'react-router-dom';
import { paramsToObject } from '../../utilities/paramsToObject';

export const EditProjectPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const onSubmit = useCallback((data: ProjectFormValues) => {
    updateTask(data, setIsLoading);
  }, []);

  const getFormInitialValues = useCallback((project: ProjectOutDto): ProjectFormValues => {
    return {
      id: project.id,
      name: project.name,
    };
  }, []);

  return (
    <Card style={{ maxWidth: '620px' }}>
      <ProjectForm onSubmit={onSubmit} isLoading={isLoading} initialValue={getFormInitialValues(paramsToObject(searchParams) as ProjectOutDto)} />
    </Card>
  );
};

const updateTask = (data: ProjectFormValues, setIsLoading: Dispatch<SetStateAction<boolean>>): void => {
  setIsLoading(true);
  ApiClient.projectsController_update(new ProjectInDto({ ...data }))
    .then(() => message.success('Project updated succesfully'))
    .then(() => {
      document.location.href = routes.projectsList.route;
    })
    .catch(() => message.error('Could not update projects, please try again later'))
    .finally(() => setIsLoading(false));
};
