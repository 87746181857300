import { Button } from 'antd';
import { useState } from 'react';

export const AdLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      icon={<img src="signin_microsoft.svg" height="12px" alt="ms-logo" />}
      size="large"
      onClick={() => setIsLoading(true)}
      loading={isLoading}
      href={`https://login.microsoftonline.com/${process.env.REACT_APP_ADTENANTID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_ADCLIENTID}&response_type=code&redirect_uri=${process.env.REACT_APP_ADREDIRECTURL}&response_mode=query&scope=https://graph.microsoft.com/User.Read&state=nort6g16o38G9IXPHlLe2V77OaA09U`}
    >
      Login with Microsoft Account
    </Button>
  );
};
