import { ApiKeys } from '../../services/api/apiKeys';
import { ApiClient } from '../../services/api/api';
import TableFilter from '../../components/dumb/tableFilter/TableFilter';
import { DataTable } from '../../components/dumb/dataTable/DataTable';
import { useCallback, useState } from 'react';
import { ProjectFilterModel, ProjectsFilterResponse } from '../../services/api/apiClient';
import { useGetProjectsListColumns } from './utilities/useGetProjectsListColumns';
import { Button } from 'antd';
import { useGetProjectsListActions } from './utilities/useGetProjectsListActions';
import { ReloadOutlined } from '@ant-design/icons';
import { useApiCall } from '../../utilities/useApiCall';
import { routes } from '../../routing/routes';
import { useIsAdmin } from '../../hooks/useIsAdmin';

const defaultFilter = new ProjectFilterModel({ skip: 0, take: 20 });

export const ProjectsListPage = () => {
  const [filter, setFilter] = useState<ProjectFilterModel>(defaultFilter);
  const { data, isLoading, refetch } = useApiCall<ProjectsFilterResponse>([ApiKeys.getProjects, filter], () => ApiClient.projectsController_getFiltered(filter));
  const isAdmin = useIsAdmin();

  const onRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const columns = useGetProjectsListColumns();
  const actions = useGetProjectsListActions(onRefresh);

  return (
    <>
      <TableFilter
        filter={filter}
        showSearch
        total={data?.total ?? 0}
        onChange={(v) => setFilter(new ProjectFilterModel({ ...v }))}
        onSearch={(s) => setFilter((old) => new ProjectFilterModel({ ...old, searchValue: s }))}
      >
        <div id="actions" style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          {isAdmin && (
            <Button type="primary" href={routes.createProject.route}>
              Create Project
            </Button>
          )}
          <Button icon={<ReloadOutlined />} loading={isLoading} type="dashed" onClick={() => refetch()}>
            Refresh
          </Button>
        </div>

        <DataTable data={data?.projects ?? []} columns={columns} loading={isLoading} actions={isAdmin ? actions : []} />
      </TableFilter>
    </>
  );
};
