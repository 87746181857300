import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios/index';
import { AuthenticationOutDto, AutogeneratedApiClient, RefreshTokenInDto } from './apiClient';
import { jwtDecode } from 'jwt-decode';
import { routes } from '../../routing/routes';

export const api = Axios.create({
  baseURL: '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;

api.interceptors.response.use(
  async (response: AxiosResponse<any>) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      document.location.href = routes.home.route;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

api.interceptors.request.use(
  async (config: AxiosRequestConfig<any>) => {
    while (isRefreshing && !config.url?.includes('/api/authentication/refresh-token')) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    const auth = getFromLocalStorage();

    if (auth && config?.headers) {
      let token: string | undefined = auth.token;

      if (isTokenExpired(auth.token) && !config.url?.includes('/api/authentication/refresh-token')) {
        isRefreshing = true;

        const freshAuth = await refreshToken(auth);

        addToLocalStorage(freshAuth);
        token = freshAuth?.token;
        isRefreshing = false;
      }

      config.headers['Authorization'] = ` Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    localStorage.clear();
    document.location.href = routes.home.route;
    isRefreshing = false;
    return Promise.reject(error);
  },
);

const isTokenExpired = (token: string): boolean => {
  const decodedHeader = jwtDecode(token, { header: false });

  if (decodedHeader?.exp && Date.now() >= decodedHeader?.exp * 1000) {
    return true;
  }

  return false;
};

const addToLocalStorage = (response: AuthenticationOutDto | undefined) => {
  if (response) {
    localStorage.setItem('Authentication', JSON.stringify(response));
  }
};

const getFromLocalStorage = (): AuthenticationOutDto | undefined => {
  const auth = localStorage.getItem('Authentication');
  if (auth) {
    return JSON.parse(auth) as AuthenticationOutDto;
  }
};

const refreshToken = async (auth: AuthenticationOutDto): Promise<AuthenticationOutDto | undefined> => {
  try {
    const resp = await ApiClient.authenticationController_refreshToken(new RefreshTokenInDto({ email: auth.user.email, refreshToken: auth.refreshToken }));
    return resp;
  } catch (error) {
    localStorage.clear();
    isRefreshing = false;
    document.location.href = routes.home.route;
  }
};

export const ApiClient = new AutogeneratedApiClient('', api);
