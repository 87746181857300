import { Button, Input, message } from 'antd';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TaskInDto } from '../../services/api/apiClient';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { ApiClient } from '../../services/api/api';
import { routes } from '../../routing/routes';
import { ValidatedImportTasksTable } from '../../components/smart/ValidatedImportTasksTable/ValidatedImportTasksTable';

export const ImportTasksPage = () => {
  const [csvValue, setCsvValue] = useState<string>('');
  const [validatedTasks, setValidatedTasks] = useState<TaskInDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const onSubmit = useCallback(() => {
    importTasks(validatedTasks, setIsLoading);
  }, [validatedTasks, setIsLoading]);

  useEffect(() => {
    parseInput(csvValue, setValidatedTasks, setIsValidated);
  }, [csvValue]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Input.TextArea
        size="large"
        rows={6}
        placeholder="Paste here csv data to validate. Columns: Project, When, How Long (days), What, Reference"
        value={csvValue}
        onChange={(e) => setCsvValue(e.target.value)}
      />

      <ValidatedImportTasksTable tasks={validatedTasks} />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disabled={!isValidated} onClick={() => onSubmit()} loading={isLoading} type="primary">
          Submit
        </Button>
      </div>
    </div>
  );
};

const importTasks = (data: TaskInDto[], setIsLoading: Dispatch<SetStateAction<boolean>>): void => {
  setIsLoading(true);

  ApiClient.tasksController_createMany(data)
    .then(() => {
      message.success('Succesfully imported!');
      document.location.href = routes.tasksList.route;
    })
    .catch(() => message.error('Could not import tasks, please double check the projects names and try again later'))
    .finally(() => setIsLoading(false));
};

const parseInput = (csvValue: string, setValidatedTasks: Dispatch<SetStateAction<TaskInDto[]>>, setIsValidated: Dispatch<SetStateAction<boolean>>) => {
  Papa.parse<unknown>(csvValue, {
    header: false,
    complete: (results) => {
      let tasks = results.data.map(
        (csvItem: any) =>
          new TaskInDto({
            projectName: csvItem[0],
            projectId: 0,
            weekStart: dayjs(csvItem[1], 'DD.MM.YYYY').startOf('week').toDate(),
            weekEnd: dayjs(csvItem[1], 'DD.MM.YYYY').endOf('week').add(-59, 'seconds').toDate(),
            hours: Number(csvItem[2]?.replace(',', '.')) * 8,
            task: csvItem[3],
            reference: csvItem[4],
          }),
      );

      tasks = tasks.filter((task) => task.projectName);

      if (tasks.length > 0) {
        setValidatedTasks(tasks);
        setIsValidated(true);
      } else {
        setValidatedTasks([]);
        setIsValidated(false);
      }
    },
    error: (error: any) => {
      console.error('Error parsing CSV: ', error);
      setIsValidated(false);
    },
  });
};
