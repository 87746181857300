import { Helmet } from 'react-helmet';

import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const AuthLayout = ({ children }: Props) => {
  return (
    <>
      <Helmet>
        <title>Login | MOSAIC LAB</title>
      </Helmet>
      <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', gap: '50px', justifyContent: 'center', alignItems: 'center' }}>{children}</div>
    </>
  );
};
