import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiClient } from '../../services/api/api';
import { AuthenticationInDto } from '../../services/api/apiClient';
import { Spin, message } from 'antd';
import { AuthContext } from '../../contexts/auth/AuthContext';
import { routes } from '../../routing/routes';
import { MosaicLogo } from '../../components/dumb/mosaicLogo/MosaicLogo';

let isLogginIn = false;

export const LoginPage = () => {
  const [searchParams] = useSearchParams({});
  const { setAuth } = useContext(AuthContext);
  const params: any[] = [];

  searchParams.forEach((value, key) => {
    params.push([key, value]);
  });

  useEffect(() => {
    if (isLogginIn) {
      return;
    }

    isLogginIn = true;

    const code = searchParams.get('code');

    if (code) {
      ApiClient.authenticationController_authenticate(
        new AuthenticationInDto({
          authorizationCode: code,
          redirectUri: process.env.REACT_APP_ADREDIRECTURL as string,
        }),
      )
        .then((response) => {
          localStorage.setItem('Authentication', JSON.stringify(response));
          setAuth(response);
          document.location.href = routes.tasksList.route;
        })
        .catch((error) => {
          setAuth(null);
          message.error(`Could not authenticate, err: ${error}`);

          document.location.href = routes.home.route;
        })
        .finally(() => {
          isLogginIn = false;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <MosaicLogo />
      <Spin size="large" />
    </>
  );
};
