import styled from '@emotion/styled';
import { Table } from 'antd';

export const StyledDataTable = styled(Table)`
  margin: 20px auto 20px auto;
  width: 1366px;
  height: fit-content;

  min-width: 1366px;

  table {
    thead {
      tr {
        th {
          font-weight: 600;
          font-size: 0.7rem;
          padding: 0.8rem 1.6rem;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0.5rem 1.6rem;
          font-size: 0.7rem;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 100%;
    min-width: 100vw;
    overflow: scroll;
  }
`;
