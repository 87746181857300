import { Select } from 'antd';
import { useApiCall } from '../../../utilities/useApiCall';
import { ApiKeys } from '../../../services/api/apiKeys';
import { ApiClient } from '../../../services/api/api';
import { UserFilterModel, UsersFilterResponse } from '../../../services/api/apiClient';

type Props = {
  onChange?: (value: number) => void;
  value?: number;
  style?: React.CSSProperties;
};

export const UsersSelect = (props: Props) => {
  const { data, isLoading } = useApiCall<UsersFilterResponse>([ApiKeys.getUsers, 'select'], () => ApiClient.usersController_filter(new UserFilterModel({ skip: 0, take: 50 })));

  const filterOption = (input: string, option?: { label: string; value: number }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return <Select style={{ width: '100%' }} placeholder="Search users..." showSearch allowClear filterOption={filterOption} loading={isLoading} options={data?.users?.map((user) => ({ label: user.firstName + ' ' + user.lastName, value: user.id }))} {...props}></Select>;
};
