import React from 'react';
import ReactDOM from 'react-dom/client';
import { MainLayout } from './layouts/mainLayout/MainLayout';
import { routes } from './routing/routes';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Global } from '@emotion/react';
import { ConfigProvider } from 'antd';
import { TasksListPage } from './pages/tasks-list/TasksListPage';
import { ProjectsListPage } from './pages/projects-list/ProjectsListPage';
import { css } from '@emotion/react';
import { LoginPage } from './pages/login/LoginPage';
import { AuthContextProvider } from './contexts/auth/AuthContext';
import { AuthLayout } from './layouts/AuthLayout/AuthLayout';
import { CreateTaskPage } from './pages/create-task/CreateTaskPage';
import { EditTaskPage } from './pages/edit-task/EditTaskPage';
import { CreateProjectPage } from './pages/create-project/CreateProjectPage';
import { EditProjectPage } from './pages/edit-project/EditProjectPage';
import { UsersListPage } from './pages/users-list/UsersListPage';
import { ImportTasksPage } from './pages/imports-tasks/ImportTasksPage';
import plPL from 'antd/locale/pl_PL';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.Ls.en.weekStart = 1;

const router = createBrowserRouter([
  {
    path: routes.home.route,
    element: (
      <AuthLayout>
        <HomePage />
      </AuthLayout>
    ),
  },
  {
    path: routes.tasksList.route,
    element: (
      <MainLayout title={routes.tasksList.title} breadcrumb={routes.tasksList.breadcrumbs}>
        <TasksListPage />
      </MainLayout>
    ),
  },
  {
    path: routes.createTask.route,
    element: (
      <MainLayout title={routes.createTask.title} breadcrumb={routes.createTask.breadcrumbs}>
        <CreateTaskPage />
      </MainLayout>
    ),
  },
  {
    path: routes.importTasks.route,
    element: (
      <MainLayout title={routes.importTasks.title} breadcrumb={routes.importTasks.breadcrumbs}>
        <ImportTasksPage />
      </MainLayout>
    ),
  },
  {
    path: routes.editTask.route,
    element: (
      <MainLayout title={routes.editTask.title} breadcrumb={routes.editTask.breadcrumbs}>
        <EditTaskPage />
      </MainLayout>
    ),
  },
  {
    path: routes.projectsList.route,
    element: (
      <MainLayout title={routes.projectsList.title} breadcrumb={routes.projectsList.breadcrumbs}>
        <ProjectsListPage />
      </MainLayout>
    ),
  },
  {
    path: routes.createProject.route,
    element: (
      <MainLayout title={routes.createProject.title} breadcrumb={routes.createProject.breadcrumbs}>
        <CreateProjectPage />
      </MainLayout>
    ),
  },
  {
    path: routes.editProject.route,
    element: (
      <MainLayout title={routes.editProject.title} breadcrumb={routes.editProject.breadcrumbs}>
        <EditProjectPage />
      </MainLayout>
    ),
  },
  {
    path: routes.login.route,
    element: (
      <AuthLayout>
        <LoginPage />
      </AuthLayout>
    ),
  },
  {
    path: routes.usersList.route,
    element: (
      <MainLayout title={routes.usersList.title} breadcrumb={routes.usersList.breadcrumbs}>
        <UsersListPage />
      </MainLayout>
    ),
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Global
      styles={css`
        body {
          margin: 0;
          /* Styles for webkit-based browsers */
          &::-webkit-scrollbar {
            width: 8px; /* width of the entire scrollbar */
          }
          &::-webkit-scrollbar-track {
            background: #f1f1f1; /* color of the tracking area */
          }
          &::-webkit-scrollbar-thumb {
            background: #888; /* color of the scroll thumb */
            border-radius: 4px; /* roundness of the scroll thumb */
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #555; /* color of the scroll thumb when hovered */
          }
        }

        /* Styles for IE, Edge, and Firefox */
        * {
          scrollbar-width: thin; /* "auto" or "thin" */
          scrollbar-color: #888 #f1f1f1; /* thumb and track color */
        }
      `}
    />

    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }} locale={plPL}>
        <AuthContextProvider>
          <RouterProvider router={router}></RouterProvider>
        </AuthContextProvider>
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
