import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Avatar, Breadcrumb, Button, Spin, Typography } from 'antd';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { ReactNode, useCallback, useContext } from 'react';
import { routes } from '../../routing/routes';
import Title from 'antd/es/typography/Title';
import { AuthContext } from '../../contexts/auth/AuthContext';
import { MosaicLogo } from '../../components/dumb/mosaicLogo/MosaicLogo';
import { Helmet } from 'react-helmet';
import { useIsAdmin } from '../../hooks/useIsAdmin';

const { Paragraph } = Typography;

const StyledMain = styled.div`
  padding: 10px;
  max-width: 1366px;
  min-width: 1366px;
  margin: 0 auto;

  @media only screen and (max-width: 480px) {
    max-width: calc(100vw - 20px);
    min-width: calc(100vw - 20px);
    padding: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

const StyledHeader = styled.div`
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 30px;
  width: calc(100%-60);
`;

const StyledDesktopNavi = styled.div`
  display: block;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const StyledDesktopLoggedUser = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const StyledHamburgerMobileMenu = styled(MenuOutlined)`
  display: none;

  @media only screen and (max-width: 480px) {
    display: block;
  }
`;

type Props = {
  title: string;
  children: ReactNode;
  breadcrumb: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
};

export const MainLayout = ({ children, title, breadcrumb }: Props) => {
  const { auth } = useContext(AuthContext);

  const logout = useCallback(() => {
    localStorage.clear();
    document.location.href = `https://login.microsoftonline.com/${process.env.REACT_APP_ADTENANTID}/oauth2/v2.0/logout`;
  }, []);

  const isAdmin = useIsAdmin();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', maxWidth: '100%' }}>
      <Helmet>
        <title>{title} | MOSAIC LAB</title>
      </Helmet>

      <StyledHeader>
        <StyledDesktopNavi>
          <Button type="link" href={routes.tasksList.route}>
            Tasks
          </Button>
          <Button type="link" href={routes.projectsList.route}>
            Projects
          </Button>
          {isAdmin && (
            <Button type="link" href={routes.usersList.route}>
              Users
            </Button>
          )}
        </StyledDesktopNavi>

        <MosaicLogo />

        <StyledDesktopLoggedUser>
          <Avatar size="large" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
          <Paragraph style={{ margin: 0, fontSize: '12px', width: '100px' }}>{!auth ? <Spin size="small" /> : auth?.user?.firstName + ' ' + auth?.user?.lastName}</Paragraph>
          <Button danger type="text" onClick={() => logout()}>
            Logout
          </Button>
        </StyledDesktopLoggedUser>

        <StyledHamburgerMobileMenu />
      </StyledHeader>

      <StyledMain>
        <Title style={{ fontSize: '26px' }}>{title}</Title>
        <Breadcrumb items={breadcrumb} />

        <div style={{ paddingTop: '20px', minHeight: '120vh' }}>{children}</div>
      </StyledMain>
    </div>
  );
};
