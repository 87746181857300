interface ScrollToElement {
  id: string;
  duration?: number;
  delay?: number;
  offset?: number;
  animationType?: (t: any, b: any, c: any, d: any) => number;
}

const ease = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
  t -= 2;
  return (c / 2) * (Math.sqrt(1 - t * t) + 1) + b;
};

export const scrollToElement = ({ id, duration = 300, delay = 0, offset = 0, animationType = ease }: ScrollToElement) => {
  const element = document.getElementById(id);

  if (element === null) return null;

  const elementPosition = element.getBoundingClientRect().top + window.scrollY + offset;
  const startPosition = window.pageYOffset;
  const distance = Number(elementPosition) - startPosition;
  let startTime: any = null;

  const animation = (currentTime: number) => {
    if (startTime === null) {
      startTime = currentTime;
    }

    const timeElapsed = currentTime - startTime;
    const run = animationType(timeElapsed, startPosition, distance, duration);

    window.scroll(0, run);

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  };

  setTimeout(() => {
    requestAnimationFrame(animation);
  }, delay);
};
