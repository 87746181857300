import { MosaicLogo } from '../components/dumb/mosaicLogo/MosaicLogo';
import { AdLoginButton } from '../components/smart/AdLoginButton/AdLoginButton';
import { Helmet } from 'react-helmet';

export const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Timesheets v2 | MOSAIC LAB</title>
      </Helmet>

      <MosaicLogo />
      <AdLoginButton />
    </>
  );
};
