import { DataTableColumns } from '../../../components/dumb/dataTable/DataTable';
import { TaskOutDto } from '../../../services/api/apiClient';
import { formatDate, formatDateWithTime } from '../../../utilities/formatDate';
import { formatNumberWithLocale } from '../../../utilities/intl';

export const useGetTasksListColumns = (): DataTableColumns<TaskOutDto>[] => {
  return [
    {
      title: 'Id',
      render: (entity: TaskOutDto) => entity.id,
      width: '5%',
    },
    {
      title: 'Project',
      render: (entity: TaskOutDto) => entity.projectName,
      width: '10%',
    },
    {
      title: 'Added date',
      render: (entity: TaskOutDto) => formatDateWithTime(entity.addedDate),
      width: '10%',
    },
    {
      title: 'Week',
      render: (entity: TaskOutDto) => formatDate(entity.weekStart) + ' - ' + formatDate(entity.weekEnd),
      width: '15%',
    },
    {
      title: 'User',
      render: (entity: TaskOutDto) => entity.user?.email,
      width: '20%',
    },
    {
      title: 'Task',
      render: (entity: TaskOutDto) => entity.task,
      width: '20%',
    },
    {
      title: 'Ref',
      render: (entity: TaskOutDto) => entity.reference,
      width: '5%',
    },
    {
      title: 'Time',
      render: (entity: TaskOutDto) => getHoursFormat(entity.hours),
      width: '10%',
    },
  ];
};

const getHoursFormat = (hours: number) => {
  if (hours === 1) {
    return '1,00 Hour';
  }

  return `${formatNumberWithLocale(hours)} Hours`;
};
