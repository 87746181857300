export const debounce = (fn: any, delay: any) => {
  let timeoutId: any;

  return (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, delay);
  };
};
