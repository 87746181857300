import { ApiKeys } from '../../services/api/apiKeys';
import { ApiClient } from '../../services/api/api';
import TableFilter from '../../components/dumb/tableFilter/TableFilter';
import { DataTable } from '../../components/dumb/dataTable/DataTable';
import { useCallback, useState } from 'react';
import { TaskFilterModel, TasksFilterResponse, TasksSummaryOutDto } from '../../services/api/apiClient';
import { useGetTasksListColumns } from './utilities/useGetTasksListColumns';
import { Button } from 'antd';
import { useGetTasksListActions } from './utilities/useGetTasksListActions';
import { ClearOutlined, ImportOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useApiCall } from '../../utilities/useApiCall';
import { TaskListSummary } from '../../components/smart/TaskListSummary/TaskListSummary';
import { routes } from '../../routing/routes';
import { ProjectsSelect } from '../../components/smart/ProjectsSelect/ProjectsSelect';
import { UsersSelect } from '../../components/smart/UsersSelect/UsersSelect';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import MyDatePicker from '../../components/dumb/myDatePicker/MyDatePicker';
import dayjs from 'dayjs';

const defaultFilter = new TaskFilterModel({
  skip: 0,
  take: 20,
  searchValue: '',
});

export const TasksListPage = () => {
  const isAdmin = useIsAdmin();
  const [filter, setFilter] = useState<TaskFilterModel>(defaultFilter);
  const { data, isLoading, refetch } = useApiCall<TasksFilterResponse>([ApiKeys.getTasks, filter], () => ApiClient.tasksController_getFiltered(filter));
  const { data: summaryData, refetch: summaryRefetch } = useApiCall<TasksSummaryOutDto>([ApiKeys.getSummary, filter], () => ApiClient.tasksController_getSummary(filter));

  const onRefresh = useCallback(() => {
    summaryRefetch();
    refetch();
  }, [refetch, summaryRefetch]);

  const columns = useGetTasksListColumns();
  const actions = useGetTasksListActions(onRefresh);

  return (
    <>
      <TableFilter
        filter={filter}
        showSearch
        total={data?.total ?? 0}
        onSearch={(s) => setFilter((old) => new TaskFilterModel({ ...old, searchValue: s }))}
        onChange={(v) => setFilter(new TaskFilterModel({ ...filter, ...v }))}
      >
        <div id="advanced-filters" style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          <MyDatePicker.RangePicker
            presets={[
              {
                label: 'This Week',
                value: [dayjs().utc().startOf('week'), dayjs().utc().endOf('week')],
              },
              {
                label: 'Last Week',
                value: [dayjs().utc().subtract(1, 'week').startOf('week'), dayjs().utc().subtract(1, 'week').endOf('week')],
              },
              {
                label: 'Last Month',
                value: [dayjs().utc().subtract(1, 'month').startOf('month'), dayjs().utc().subtract(1, 'month').endOf('month')],
              },
              {
                label: 'This Month',
                value: [dayjs().utc().startOf('month'), dayjs().utc().endOf('month')],
              },
              {
                label: 'This Year',
                value: [dayjs().utc().startOf('year'), dayjs().utc().endOf('year')],
              },
              {
                label: 'Last Year',
                value: [dayjs().utc().subtract(1, 'year').startOf('year'), dayjs().utc().subtract(1, 'year').endOf('year')],
              },
            ]}
            value={filter.startDate && filter.endDate ? [dayjs(filter.startDate).utc(), dayjs(filter.endDate).utc()] : undefined}
            onChange={(v) =>
              setFilter(
                new TaskFilterModel({
                  ...filter,
                  startDate: v ? (v[0]?.isUTC() ? v[0]?.startOf('day').toDate() : v[0]?.startOf('day').add(dayjs().utcOffset(), 'minutes').toDate()) : undefined,
                  endDate: v ? (v[1]?.isUTC() ? v[1]?.endOf('day').toDate() : v[1]?.endOf('day').add(dayjs().utcOffset(), 'minutes').toDate()) : undefined,
                }),
              )
            }
          />

          <ProjectsSelect style={{ width: '300px' }} value={filter.projectId} onChange={(projectId) => setFilter(new TaskFilterModel({ ...filter, projectId: projectId }))} />

          {isAdmin && <UsersSelect style={{ width: '300px' }} value={filter.userId} onChange={(userId) => setFilter(new TaskFilterModel({ ...filter, userId: userId }))} />}
        </div>

        <div id="actions" style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          <Button icon={<PlusOutlined />} href={routes.createTask.route} type="primary">
            Create Task
          </Button>
          <Button icon={<ImportOutlined />} href={routes.importTasks.route} type="primary">
            Import Tasks
          </Button>
          <Button icon={<ReloadOutlined />} loading={isLoading} type="dashed" onClick={() => onRefresh()}>
            Refresh
          </Button>
          <Button icon={<ClearOutlined />} type="dashed" onClick={() => setFilter(defaultFilter)} disabled={filter === defaultFilter}>
            Clear filters
          </Button>
        </div>
        <TaskListSummary data={summaryData} />

        <DataTable data={data?.tasks ?? []} columns={columns} loading={isLoading} actions={actions} />
      </TableFilter>
    </>
  );
};
